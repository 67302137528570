const getExportForm = (uriParams, pageNumber, exportParams) => {

  let formExportData = new FormData()
  formExportData.append('q', uriParams.searchPhrase)
  formExportData.append('rows', 10000)
  formExportData.append('start', 0)
  formExportData.append('filterQuery', uriParams.filterQuery)
  formExportData.append('facetQuery', uriParams.facetQuery)
  formExportData.append('tabQuery', uriParams.tabQuery)
  formExportData.append('sortQuery', uriParams.sortQuery)
  formExportData.append('fl', exportParams.fl)
  formExportData.append('exportType', exportParams.exportType)
  return formExportData
}

export default getExportForm
