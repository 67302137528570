import createPersistedState from "vuex-persistedstate";
import { createStore } from 'vuex'
import axios from "axios"
import { i18n } from "../main";

import getForm from './helpers/getForm'
import getExportForm from './helpers/getExportForm'
import getIdForm from "./helpers/getIdForm"
import getUris from "./helpers/getUris"
import processFacetResults from "./helpers/handleFacetResults"
import getSearchResultsFromResponse from './helpers/handleSearchResponse'
import EnvProvider from 'jvjr-docker-env';


// no cache in response from axios
axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
}

export default createStore({
  state: {
    rootConfig:{
      API_URL: '',
      API_SEARCH: '',
      API_EXPORT: '',
    },
    recordForContextMenu:{},
    searchResults: [],
    allTabs:{},
    selectedTabs:{
      meta:{},
      items:[]
    },
    uriParams: {
      searchPhrase: "",
      searchStart: 0,
      itemsPerPage: 0,
      facetQuery: "",
      filterQuery: "",
      newFilterQuery: "",
      oldFilterQuery: "",
      idQuery: "",
      idBubbleQuery: "",
      newIdQuery: "",
      oldIdQuery: "",
      tabQuery: "",
      sortQuery: "",
    },
    exportParams: {
      fl: '',
      exportType: '',
      exportEnd: ''
    },
    highlightedId: '',
    loggedUser: '',
    pageNumber: 1,
    numFound: 0,
    selectedTab: {},
    //selectedTabs: [],
    msg: [],
    HighlightText: "",
    showOverlay: false,
    overlayMessage: '',
    facets: [],
    exportUrl: '',
    loadingVisibility: false,
    savedExpressions: [],
    top10Expressions: [],
    recentExpressions: [],
    resultCount: 10,
    // newresultCount: 0,
    // resultsCountFromCheckbox: -1,
    filterBubblesArray: []
  },
  mutations: {
    calculatePageCount (state) {
      if (state.resultCount <= state.uriParams.itemsPerPage) {
        state.pageCount = 0
      }
      state.pageCount = parseInt(state.resultCount / state.uriParams.itemsPerPage) + 1
    },
    setsavedExpressions(state, savedExpressions) {
      state.savedExpressions = savedExpressions;
    },
    settop10Expressions(state, top10Expressions) {
      state.top10Expressions = top10Expressions;
    },
    setrecentExpressions(state, recentExpressions) {
      console.log('setting recentExpressions')
      state.recentExpressions = recentExpressions;
    },
    setLoggedUser(state, loggedUser) {
      state.loggedUser = loggedUser
    },
    setRoot (state, rootConfig) {
       state.rootConfig = rootConfig
    },
    setRecordForContextMenu (state, record) {
       state.recordForContextMenu = record
    },
    setSearchResults (state, searchResults) {
       state.searchResults = searchResults
    },
    setAllTabs (state, tabs) {
       state.allTabs = tabs
    },
    setHighlightText(state, HighlightText) {
      state.HighlightText = HighlightText
    },
    setShowOverlay(state, payload) {
      state.showOverlay = payload.show;
      state.overlayMessage = payload.message; // Update overlay message in the store
    },
    // selectCheckboxesForSelectedTabs (state, selectedTabs) {
    //   for (let i = 0; i < state.allTabs.items.length; i += 1) {
    //     for (let j = 0; j < selectedTabs.items.length; j += 1) {
    //       if (selectedTabs.items[j].label === state.allTabs.items[i].label && selectedTabs.items[j].Selected) {
    //         state.allTabs.items[i].Selected = true
    //       }
    //     }
    //   }
    // },
    // setSelectedTabs (state, selectedTabs) {
    //   state.selectedTabs = selectedTabs
    // },
    setUriParameters (state, newParams) {
      state.uriParams = {
        ...state.uriParams,
        ...newParams
      }
    },
    setExportParameters (state, newParams) {
      state.exportParams = {
        ...state.uriParams,
        ...newParams
      }
    },
    setPageNumber (state, number) {
      state.pageNumber = number
    },
    setNumFound (state, numFound) {
      state.numFound = numFound
    },
    setHighlightedId (state, highlightedId) {
      state.highlightedId = highlightedId
    },
    setSelectedTab (state, selectedTab) {
       state.selectedTab = selectedTab
    },
    setSelectedTabs (state,selectedTabs){
      state.selectedTabs = selectedTabs
    },
    setFacetResults (state, facets) {
      state.facets = facets
    },
    setLoadingVisibility (state, visibility) {
      state.loadingVisibility = visibility
    },
    setExportUrl (state, url) {
      state.exportUrl = url
    },
    setFacetsBubblesArray (state, tempFacetArray) {
      state.filterBubblesArray = tempFacetArray
    },
    pushMsg (state, data){
      state.msg.push({
        type: data.type, 
        text: data.text
      })
      setTimeout(() => {
        state.msg.shift()
      }, 7000)
    },
    popMsg (state) {
      state.msg.shift()
    },
    clearMsg (state) {
      state.msg.length = 0
    }
  },
  actions: {
    showOverlay({ commit }, message) {
      console.log('setting overlay')
      commit('setShowOverlay', { show: true, message }); // Pass message to the mutation
    },
    hideOverlay({ commit }) {
      console.log('unsetting overlay')
      commit('setShowOverlay', { show: false, message: '' }); // Clear message when hiding overlay
    },
    async search (context) {
        try {
          context.dispatch('showOverlay', i18n.global.t("LoadingMessages.searchLoading"))
          const searchForm = getForm(context.state.uriParams, context.state.pageNumber)
          await axios.post(EnvProvider.value('API_URL') + context.state.rootConfig.API_SEARCH, searchForm, { 
            headers: { 
                "X-Forwarded-User": context.state.loggedUser
            }
        }).then(result => {
            context.dispatch('hideOverlay')
            if (result.status == 200) {
              // context.commit('pushMsg', {type: 'ok', text: 'Úspešné vyhľadávanie'})
              context.commit('setSearchResults', result.data.response.docs)
              context.commit('setAllTabs', result.data.tabs)
              context.commit('setNumFound', result.data.numFound)
            } else {
              context.commit('pushMsg', {type: 'error', text: 'Vo vyhľadávaní sa vyskytla chyba'})
            }
          })
          // const searchResults = getSearchResultsFromResponse(response.data, context.state.searchResults, context.state.rootConfig, context.state.uriParams)
          // context.commit('setSearchResults', searchResults)
        }  catch (err) {
          context.dispatch('hideOverlay')
          context.commit('pushMsg', {type: 'error', text: 'Vo vyhľadávaní sa vyskytla chyba'})
          console.log('ERROR !', err)
        }
    },
    async export (context) {
      try {
        const exportForm = getExportForm(context.state.uriParams, context.state.pageNumber, context.state.exportParams)
        await axios.post(EnvProvider.value('API_URL') + context.state.rootConfig.API_EXPORT, exportForm, { 
          headers: { 
              "X-Forwarded-User": context.state.loggedUser
          }
      }).then(result => {
        context.dispatch('hideOverlay')
          if (result.status == 200) {
            location.href = result.data
            // context.commit('pushMsg', {type: 'ok', text: 'Úspešné exportovanie výsledkov vyhľadávania'})
          } else {
            context.commit('pushMsg', {type: 'error', text: 'V exportovaní výsledkov sa vyskytla chyba'})
          }
        })
        // const searchResults = getSearchResultsFromResponse(response.data, context.state.searchResults, context.state.rootConfig, context.state.uriParams)
        // context.commit('setSearchResults', searchResults)
      }  catch (err) {
        context.dispatch('hideOverlay')
        context.commit('pushMsg', {type: 'error', text: 'V exportovaní výsledkov sa vyskytla chyba'})
        console.log('ERROR !', err)
      }
    },

    //fetch document from highlight
    async fetchDocumentContext(context) {
      try {
        const highlightedForm = getIdForm(context.state.uriParams, context.state.highlightedId)
        await axios.post(EnvProvider.value('API_URL') + context.state.rootConfig.API_HIGHLIGHT, highlightedForm, { 
          headers: { 
              "X-Forwarded-User": context.state.loggedUser
          }
      }).then(result => {
          if (result.status == 200) {
            var documentText = null;
            try {
              console.log(result.data)
              documentText = result.data.response.docs[0].content_txt_sk
              context.commit("setHighlightText", documentText)
            } catch (e) {
              console.error(e);
            }
          } else {
            context.commit("setHighlightText", '')
            context.commit('pushMsg', {type: 'error', text: 'Chyba pri zobrazení highlightu'})
          }
        })
      } catch (err) {
        console.log("Error:", err)
      }
    },
    async myProfileSearch (context, { facetSearch }) {
      try {
        if (facetSearch === undefined) {
          facetSearch = false
        }
        context.commit('setLoadingVisibility', true)
        const [searchUri, exportUri] = getUris(context.state.uriParams, context.state.rootConfig)
        context.commit('setExportUrl', exportUri)
        const response = await axios.get(searchUri)
        const data = processFacetResults(response.data.facet_counts, context.state.facets, context.state.rootConfig)
        context.commit('setFacetResults', data.result)
        if (!facetSearch) {
          const searchResults = getSearchResultsFromResponse(response.data, context.state.searchResults, context.state.rootConfig, context.state.uriParams)
          context.commit('setSearchResults', searchResults)
          context.commit('setAllTabs', data.tabsForPopover)
        }
        // select tab if not selected
        // TODO: c'mon, you can make this better
        if (Object.keys(context.state.selectedTab).length === 0) {
          context.commit('setSelectedTab', data.tabsForPopover.items[0])
        } else {
          for (let i = 0; i < data.tabsForPopover.items.length; i += 1) {
            const tab = data.tabsForPopover.items[i]
            if (tab.categoryHash === context.state.selectedTab.categoryHash) {
              context.commit('setSelectedTab', tab)
              break
            }
          }
        }
        if (!facetSearch) {
          // new function for calculating pageCount for paginator
          // if facetSearch is selected than do not refresh page numbers
          // only if someone initiate search afterwards
          context.commit('calculatePageCount')
        }
        context.commit('setLoadingVisibility', false)
      } catch (err) {
        context.commit('setLoadingVisibility', false)
        console.log('ERROR !', err)
        context.commit('setSearchResults', [])
        context.commit('setFacetResults', [])
        return {}
      }
    }
  },
  getters: {
    showOverlay: state => state.showOverlay,
    overlayMessage: state => state.overlayMessage // Add getter for overlay message
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
})

