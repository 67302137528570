<template>
    <div class="tooltip-wrapper" 
        @mouseenter="startShowTimer" 
        @mouseleave="startHideTimer">
        <slot></slot>
        <transition name="fade">
            <div v-if="show" class="tooltip">
                <span v-html="text"></span>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        text: { type: String, required: true },
        showDelay: { type: Number, default: 500 }, // Delay pred zobrazením
        hideDelay: { type: Number, default: 300 } // Delay pred skrytím
    },
    data() {
        return {
            show: false,
            showTimeout: null,
            hideTimeout: null
        };
    },
    methods: {
        startShowTimer() {
            clearTimeout(this.hideTimeout); 
            this.showTimeout = setTimeout(() => {
                this.show = true;
            }, this.showDelay);
        },
        startHideTimer() {
            clearTimeout(this.showTimeout); 
            this.hideTimeout = setTimeout(() => {
                this.show = false;
            }, this.hideDelay);
        }
    }
};
</script>

<style scoped>
.tooltip-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    color: black;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    white-space: nowrap;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 999;
    border: 1px solid #ccc;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}


.tooltip-wrapper:hover .tooltip {
    opacity: 1;
    visibility: visible;
}
</style>
