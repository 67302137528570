<template>
  <div class="modalViewExport">
    <h3> {{ $t("authorcards.outputChoice") }} </h3>
    <p class="exportMessage"> {{ $t("authorcards.export") }} {{ $store.state.numFound }} {{ $t("authorcards.records") }} </p>
    <div class="line"></div>
    <div class="exportSection">
      
      <div>
        <h3> {{ $t("results.format") }} </h3>
        <div class="exportSelectFormats">
          <input class="exportMethodbutton" type="button" value="JSON" @click="activeExportElement = 'zip'"  :class="{ active : activeExportElement == 'zip' }">
          <input class="exportMethodbutton" type="button" value="CSV" @click="activeExportElement = 'csv'"  :class="{ active : activeExportElement == 'csv' }">
          <input class="exportMethodbutton" type="button" value="Excel"  @click="activeExportElement = 'xlsx'" :class="{ active : activeExportElement == 'xlsx' }">
        </div>
      </div>
    </div>
    <div class="exportMenu">
      <button class="backbutton" @click="$root.exportModalShow = !$root.exportModalShow"> {{ $t("results.backbutton") }} </button>   
      <button class="exportbutton" @click="callExportMethod"> {{ $t("leftSideMenu.exportbutton") }} </button>
    </div>
  </div>  
</template>

<script>
import EnvProvider from 'jvjr-docker-env';
import { useStore } from 'vuex';

export default {
    name: "ExportMenu",
    data(){
        return{
          activeExportElement: 'zip',
        }
    },
    methods: {
      callExportMethod () {
        this.$store.dispatch('showOverlay',this.$t("leftSideMenu.export.exportTitle"));
        if (this.activeExportElement === 'csv' || this.activeExportElement === 'xlsx') {
            this.$store.commit('setExportParameters', {
              fl: this.setExportMetadata(),
              exportType: this.setExportType(),
              exportEnd: this.activeExportElement
            })
            this.$store.dispatch('export', {})
        } else {
          var exportURI = EnvProvider.value('REPORT_URL') + this.$root.API_REPORT + '/' + this.activeExportElement + '?q=' + this.$store.state.uriParams.searchPhrase + this.$store.state.uriParams.filterQuery + '&exportType=export'

          this.axios.get(exportURI, { responseType: 'blob' })
              .then(response => {
                  const contentDisposition = response.headers['content-disposition'];
                  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                  const matches = filenameRegex.exec(contentDisposition);
                  let filename = 'export-json.zip'; // Default filename
                  if (matches != null && matches[1]) {
                      filename = matches[1].replace(/['"]/g, '');
                  }
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', filename); // You can set the filename here
                  document.body.appendChild(link);
                  link.click();
                  this.$store.dispatch('hideOverlay');
              })
              .catch(error => {
                  console.error('Download failed:', error);
                  this.$store.commit('pushMsg', {type: 'error', text: 'V exportovaní reportu sa vyskytla chyba'});
                  this.$store.dispatch('hideOverlay');
              });
          // var fileLink = document.createElement('a');
          // fileLink.href = exportURI;
          // console.log(fileLink)
          // document.body.appendChild(fileLink);
          // fileLink.setAttribute('target', '_blank');
          // fileLink.click();
        }
      },
      setExportMetadata () {
        var fl = '&fl=Meno:name,Id_dokumentu:documentId,Zdroj:sourceId,Autor:createdBy,Dátum_vytvorenia:creationDate'
        return fl
      },
      setExportType () {
        if (this.activeExportElement === 'csv') {
          return '&wt=csv'
        } else if (this.activeExportElement === 'xlsx') {
          return '&wt=xlsx'
        }
      }
    }
}
</script>

<style lang="scss"  scoped src="@/assets/css/ExportMenu.scss"/>