<template>
    <div class="tabs">
      <div class="tabs-navigation">
        <button 
          v-for="(tab, index) in $store.state.allTabs" 
          :key="index" 
          :class="['tab-button', { active: activeTab === index } ]"
          @click="setActiveTabComponent(index, tab.id, tab)"
        >
          {{ getTabNameById(tab) }}
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import getFacetQuery from '../mixins/getFacetQuery'
  export default {
    name: "TabsComponent",
    mixins: [
        getFacetQuery
    ],
    data() {
      return {
        activeTab: 0, // Aktívna záložka (index)
      };
    },
    methods: {
      setActiveTabComponent (index, id, tab) {
        this.activeTab = index;
        this.$store.commit('setSelectedTab', tab)
        this.$store.commit('setUriParameters', {
          searchPhrase: this.$store.state.uriParams.searchPhrase,
          itemsPerPage: this.$root.recordsPerPage,
          facetQuery: this.getFacetQuery('#All'),
          filterQuery: this.$store.state.uriParams.filterQuery,
          idQuery: '',
          tabQuery: this.getTabQuery(id),
          sortQuery: this.$store.state.uriParams.sortQuery
        })
        this.createSearchLink()
      },
      getTabNameById (tab) {
        for (var i in this.$root.metadataConfigManager.metadataObjects) {
          if (this.$root.metadataConfigManager.metadataObjects[i].id == tab.id) {
            return this.$t(String(this.$root.metadataConfigManager.metadataObjects[i].label)) + ' (' + tab.value + ')'
          }
        }
      },
      createSearchLink () {
        this.$store.dispatch('search', {})
      }
    },
  };
  </script>
  
  <style scoped>
  .tabs {
    display: flex;
    flex-direction: column;
    
  }
  .tabs-navigation {
    display: flex;
    background: #FAFAFA;
    border-radius: 10px;
    
}
  .tab-button {
    font-family: 'Poppins', sans-serif;
    padding: 5px 15px;
    margin: 0;
    min-width: 200px;
    border: none;
    border-radius: 8px;
    margin: 4px;
    background-color: transparent;
    color: #9D9D9D;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    transition: 0.2s;
  }
  .tab-button.active {
    background-color: white;
    color: #2C3E50;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    font-weight: 500;
  }
  .tab-button:hover {
    color: #2C3E50;
  }
  </style>
  