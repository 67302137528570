<template>
    <div class="filter-container">
        <Menu></Menu>
        <div class="header">
            <div style="display: inline-flex; align-items: center; justify-content: center;">
                <h2>Filter</h2>
                <img @click="toggleFilters" :src="require(showFilters ? '@/assets/minus.svg' : '@/assets/plus.svg')"
                    alt="toggle" class="toggle-button">
            </div>
            <button class="download-button" @click="toggleExportModal">Stiahnuť dokumenty</button>
        </div>

        <div v-show="showFilters" class="filter-header">

            <!-- Start date -->
            <div class="filter-group custom-date-picker" @click="openDatePicker">
                <img src="../assets/calendar_icon.svg" alt="" class="custom-icon">
                <span class="custom-label">Start date:</span>
                <input type="date" ref="startDateInput" class="date-input" v-model="filter.startDate" />
            </div>

            <!-- End date -->
            <div class="filter-group custom-date-picker" @click="openEndDatePicker">
                <img src="../assets/calendar_icon.svg" alt="" class="custom-icon">
                <span class="custom-label">End date:</span>
                <input type="date" ref="endDateInput" class="date-input" v-model="filter.endDate" />
            </div>

            <!-- Document source -->
            <div class="filter-group custom-date-picker">
                <img src="../assets/source_icon.svg" alt="" class="custom-icon">
                <span class="custom-label">Document Source:</span>
                <select class="select-input" v-model="filter.source">
                    <option v-for="item in sourceOptions" :key="item.value" :value="item.value">
                        {{ $t(String(item.text)) }}
                    </option>
                </select>
            </div>

            <!-- Sort by -->
            <div class="filter-group custom-date-picker">
                <img src="../assets/order_icon.svg" alt="" class="custom-icon">
                <span class="custom-label">Sort by:</span>
                <select class="select-input" v-model="filter.sort">
                    <option v-for="item in sortOptions" :key="item.value" :value="item.value">
                        {{ $t(String(item.text)) }}
                    </option>
                </select>
            </div>
        </div>

        <div v-show="showFilters" class="inline-flex-dashed">
            <button class="download-button" @click="filterSearch">Apply filter</button>
            <div class="reset-filter" @click="filterReset">
                <img src="../assets/reset_filter.svg" alt="" />
                <a>Reset filter</a>
            </div>
        </div>

        <div class="inline-flex-spaced-evenly">
            <!-- <div class="search-bar">
                <img src="../assets/search_icon.svg" alt="" class="custom-icon">
                <input type="text" placeholder="Hľadaj zadaný výraz" v-model="$store.state.uriParams.searchPhrase" />
            </div> -->

            <TabsComponent />
        </div>

        <table class="results-table">
            <thead>
                <tr>
                    <th>Názov dokumentu</th>
                    <th>Text dokumentu</th>
                    <th>Vytvoril</th>
                    <th>Dátum vzniku</th>
                    <th>Zdroj</th>
                    <th>Akcie</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="result in $store.state.searchResults" :key="result.id" @click="logResult(result)">
                    <!-- Názov dokumentu -->
                    <td class="td-title">
                        <div class="inline-flex">
                            <img @click="displayDocumentContent(result)" src="../assets/search_doc_icon.svg" alt=""
                                class="icon">
                            <a :href="modifyLink(result)" target="_blank" class="document-link">
                                <span class="document-link">
                                    {{ result.filename }}
                                </span>
                            </a>
                        </div>
                    </td>

                    <!-- Obsah dokumentu (s textovým zvýraznením) -->
                    <Tooltip :text="result.content_txt_sk_highlight">
                        <td class="td-text" v-html="result.content_txt_sk_highlight"></td>
                    </Tooltip>
                    <!-- Vytvoril -->
                    <td class="td-createdBy">{{ result.createdBy }}</td>

                    <!-- Dátum vzniku -->
                    <td class="td-date">{{ formatDate(result.creationDate) }}</td>

                    <!-- Zdroj -->
                    <td class="td-source">{{ result.sourceId }}</td>

                    <!-- Akcie -->
                    <td class="td-actions">
                        <div class="inline-flex">
                            <img @click="setFileToAnonyma(result)" src="../assets/anonyma_icon.svg" alt="" class="icon">
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-box">
            <PageCount class="pageCount" />
        </div>

        <!-- document highlight -->
        <teleport to="body">
            <div class="modalExport" v-show="showHighlight" @click.self="closeDocumentContent">
                <div class="modalViewExport">
                    <div class="highlightHeaderContent">
                        <h2 class="highlightTitle"> {{ selectedFilename }} </h2>
                        <div id="closeHighlight" @click="closeDocumentContent"> <i class="fa fa-xmark fa-lg"
                                style="height: 100%;width: 100%;"></i> </div>
                    </div>
                    <p id="highlightLength" v-html="this.$store.state.HighlightText"></p>
                </div>
            </div>
        </teleport>

        <!-- Export modal -->
        <teleport to="body">
            <div v-show="$root.exportModalShow" class="modalExport">
                <ExportMenu />
            </div>
        </teleport>
    </div>
</template>

<script>
import TabsComponent from '../components/TabsComponent.vue';
import Tooltip from '../components/Tooltip.vue';
import Menu from "../components/Menu.vue"
import ExportMenu from "../components/SearchResults/ExportMenu.vue"
import getFacetQuery from '../mixins/getFacetQuery'
import PageCount from "../components/SearchResults/PageCount.vue"
import EnvProvider from 'jvjr-docker-env';

export default {
    components: { TabsComponent, Tooltip, PageCount, Menu, ExportMenu },
    data() {
        return {
            showHighlight: false,
            selectedFilename: '',
            signerLink: '',
            showErrorMessage: false,
            showSigner: false,
            filter: {
                startDate: '',
                endDate: '',
                source: 'all',
                sort: 'default',
            },
            showFilters: true,
            sourceOptions: [
                { text: "results.sourceAll", value: "all" },
                { text: "results.sourceCRZ", value: "crz" },
                { text: "results.sourceDisk", value: "disk" }
            ],
            sortOptions: [
                { text: "results.sortByRelevance", value: "default" },
                { text: "results.sortByCreatedByAsc", value: "createdByAsc" },
                { text: "results.sortByCreatedByDesc", value: "createdByDesc" },
                { text: "results.sortByLatest", value: "dateDesc" },
                { text: "results.sortByOldest", value: "dateAsc" },
            ],
        };
    },
    mixins: [
        getFacetQuery
    ],
    methods: {

        openDatePicker(event) {
        console.log('openDatePicker called');
        
        if (this.$refs.startDateInput) {
            this.$refs.startDateInput.showPicker();
        }

        // Po kliknutí mimo zatvoríme kalendár
        document.addEventListener('click', this.closeDatePicker);
        event.stopPropagation(); // Zabráni zatvoreniu okamžite po otvorení
    },

        openEndDatePicker() {
            if (this.$refs.endDateInput) {
                this.$refs.endDateInput.showPicker();
            }
        },

        closeDatePicker(event) {
        if (this.$refs.startDateInput && !this.$refs.startDateInput.contains(event.target)) {
            console.log('Closing date picker');
            this.$refs.startDateInput.blur(); // Skryje kalendár
            document.removeEventListener('click', this.closeDatePicker);
        }
    },

        toggleExportModal() {
            this.$root.exportModalShow = !this.$root.exportModalShow;
        },
        toggleFilters() {
            this.showFilters = !this.showFilters;
        },
        queryFormatDate(dateString) {
            // Parse the input date string
            const date = new Date(dateString);

            // Format the date to the required format (YYYY-MM-DDThh:mm:ssZ)
            const formattedDate = date.toISOString();

            return formattedDate;
        },
        escapeQueryString(query) {
            return query.replace(/([\!\*\+\&\|\(\)\[\]\{\}\^\~\?\:\"])/g, "\\$1");
        },
        filterSearch() {
            console.log('Search parameters: ')
            console.log(this.filter)

            this.$store.commit('setSelectedTab', {})
            this.$store.commit('setSelectedTabs', {
                items: []
            })
            this.$store.commit('setFacetResults', [])
            this.$store.commit('setUriParameters', {
                searchPhrase: this.$store.state.uriParams.searchPhrase,
                itemsPerPage: this.$root.recordsPerPage,
                facetQuery: this.getFacetQuery('#All'),
                filterQuery: this.getFilterQuery(),
                idQuery: this.$store.state.uriParams.idQuuery,
                tabQuery: this.$store.state.uriParams.tabQuery,
                sortQuery: this.getSortQuery(),
            })

            this.$store.dispatch('search', {})
        },
        filterReset() {
            this.filter = {
                startDate: '',
                endDate: '',
                source: 'all',
                sort: 'default',
            }
            this.filterSearch()
        },
        getFilterQuery() {
            let filterQuery = ''

            // Date Filter
            if (this.filter.startDate && this.filter.endDate) {
                filterQuery += '&fq=creationDate:[' + this.queryFormatDate(this.filter.startDate) + ' TO ' + this.queryFormatDate(this.filter.endDate) + ']';
            } else if (this.filter.startDate) {
                filterQuery += '&fq=creationDate:[' + this.queryFormatDate(this.filter.startDate) + ' TO *]';
            } else if (this.filter.endDate) {
                filterQuery += '&fq=creationDate:[* TO ' + this.queryFormatDate(this.filter.endDate) + ']';
            }

            // Document Source Filter
            if (this.filter.source !== 'all') {
                filterQuery += '&fq=sourceId:' + this.escapeQueryString(this.filter.source);
            }

            return filterQuery
        },
        getSortQuery() {
            var sortQuery = ''

            // Order By Sort
            if (this.filter.sort !== 'default') {
                if (this.filter.sort === 'dateAsc') {
                    sortQuery += '&sort=creationDate asc';
                } else if (this.filter.sort === 'dateDesc') {
                    sortQuery += '&sort=creationDate desc';
                } else if (this.filter.sort === 'createdByAsc') {
                    sortQuery += '&sort=createdBy asc';
                } else if (this.filter.sort === 'createdByDesc') {
                    sortQuery += '&sort=createdBy desc';
                }
            }

            return sortQuery
        },


        async signDocument(record) {
            this.selectedFilename = record.filename;
            this.signerLink = ''
            this.showSigner = !this.showSigner
            var doclink = ''

            // get document link
            if (record.sourceId === 'disk') {
                doclink = EnvProvider.value('FILESYSTEM_URL') + this.$root.FILESYSTEM_DOCUMENT + encodeURIComponent(record.documentId)
            } else {
                doclink = EnvProvider.value('ANONYMA_URL') + '/get/crzLink?documentLink=' + encodeURIComponent(record.documentLink)
            }

            try {
                // get the document data from disk or crz
                const response = await this.axios({
                    method: 'get',
                    url: doclink,
                    responseType: 'arraybuffer'
                });
                if (response.status != 200) {
                    console.error(response.status);
                    return;
                }

                const fileData = new Blob([response.data], { type: 'application/pdf' });
                const formData = new FormData();
                const name = record.filename.split('.')[0];
                const filename = record.filename;

                formData.append('file', fileData, filename);
                formData.append('lang', this.$i18n.locale);

                // post to signer.senseiot.sk:5020/sign-document
                const responseSigner = await this.axios.post(EnvProvider.value('SIGNER_URL') + '/sign-document', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (responseSigner.status != 200) {
                    console.error(responseSigner.status, responseSigner.data.message);
                    this.showErrorMessage = true
                    this.signerLink = null

                    return;
                }

                // get link from result and show in iframe
                this.signerLink = responseSigner.data.redirect_url;
            } catch (error) {
                if (error.response.status == 500) {
                    console.error(error.response.status, error.response.data.message);
                    this.showErrorMessage = true
                    this.signerLink = null

                    return;
                }
                else {
                    console.error(error)
                }
            }
        },
        setFileToAnonyma(record) {
            var documentLink = '&documentLink='
            if (record.sourceId === 'disk') {
                documentLink += EnvProvider.value('FILESYSTEM_URL') + this.$root.FILESYSTEM_DOCUMENT + encodeURIComponent(record.documentId)
            } else {
                documentLink += record.documentLink
            }

            var anchor = document.createElement('a')
            anchor.href = EnvProvider.value('ANONYMA_URL') + '/formview?' + documentLink.substring(1)
            anchor.target = "_blank"
            anchor.click()
        },
        modifyLink(record) {
            if (record.sourceId === 'disk') {
                return EnvProvider.value('FILESYSTEM_URL') + this.$root.FILESYSTEM_DOCUMENT + encodeURIComponent(record.documentId)
            }
            return record.documentLink
        },
        displayDocumentContent(result) {
            this.showHighlight = !this.showHighlight
            this.selectedFilename = result.filename
            this.$store.commit('setHighlightedId', result.id)
            this.$store.dispatch("fetchDocumentContext", {})
        },
        closeDocumentContent() {
            this.showHighlight = !this.showHighlight
            this.$store.commit("setHighlightText", '')
        },
        createSearchLink() {
            this.$store.dispatch("search", {});
        },
        logResult(result) {
            console.log("Result:", result);
        },
        formatDate(dateString) {
            const options = { year: "numeric", month: "long", day: "numeric" };
            return new Date(dateString).toLocaleDateString("sk-SK", options);
        }
    },
    async created() {
        this.createSearchLink();
    },
};
</script>


<style scoped>
.toggle-button {
    background: none;
    width: 16px;
    border: none;
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
    color: #007aff;
}

.filter-container {
    font-family: "Poppins", sans-serif;
    padding: 2rem;
    margin: 3rem;
    background-color: #ffffff;
    border-radius: 8px;
}

/* Hlavný nadpis a tlačidlo */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 3rem;
}

.header h2 {
    font-size: 24px;
    margin: 0;
}

.download-button {
    padding: 10px 20px;
    color: #2156A4;
    border: none;
    box-shadow: 0 0px 8px rgba(130, 145, 251, 0.5);
    border: 1px solid #2156A4;
    border-radius: 4px;
    cursor: pointer;
}

.download-button:hover {
    box-shadow: none;
}

/* Filter header */
.filter-header {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.filter-group {
    display: flex;
    flex-direction: column;
}

.inline-flex-dashed {
    display: inline-flex;
    gap: 2rem;
    width: 100%;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #E4E9F0;
    margin-bottom: 2rem;
}

.reset-filter {
    display: flex;
    align-items: center;
    gap: 5px;
}

.reset-filter a {
    color: #2156A4;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
}

.custom-date-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    padding: 8px;
    background-color: white;
    font-size: 14px;
    width: fit-content;
    gap: 8px;
}

.custom-date-picker:hover {
    border: 1px solid #2155a47e;
    box-shadow: 0 0px 8px rgba(130, 145, 251, 0.5);
}

.custom-icon {
    color: #2156A4;
    font-size: 18px;
}

.custom-label {
    font-weight: 500;
    color: #333;
}

/* .date-container {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
} */

.date-input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;

    width: 120px;
    /* Adjust width as needed */
    border: none;
    outline: none;
    font-size: 15px;
    color: #626C7B;
    cursor: pointer;
    /* Makes it clear it's clickable */
}



.text-input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    border: none;
    outline: none;
    width: 90px;
    font-size: 15px;
    color: #626C7B;
}

.select-input {
    /* appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield; */
    border: none;
    outline: none;
    width: auto;
    font-size: 15px;
    color: #626C7B;
    cursor: pointer;
}

.date-input::-webkit-calendar-picker-indicator {
    background: white;
}

.date-display {
    font-size: 14px;
    color: #333;
    font-weight: normal;
}


label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

input,
select {
    border: none;
    border-radius: 4px;
    font-size: 14px;
}

input:focus,
select:focus {
    outline: none;
}

.search-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    padding: 8px;
    background-color: white;
    font-size: 14px;
    width: fit-content;
    gap: 8px;
}

/* Vyhľadávací bar */
.search-bar input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    border: none;
    outline: none;
    width: 200px;
    font-size: 15px;
    color: #626C7B;
}

.search-bar:hover {
    cursor: pointer;
    border: 1px solid #2155a47e;
    box-shadow: 0 0px 8px rgba(130, 145, 251, 0.5);
}

/* Tabuľka výsledkov */


.results-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.results-table th {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #E4E4E4;
    color: #626C7B;
}


.results-table td {
    padding: 10px;
    /* Odstránime zbytočne veľké paddingy */
    text-align: left;
    border-bottom: 1px solid #E4E4E4;
    vertical-align: middle;
    /* Zarovnanie textu na stred */
    white-space: nowrap;
    /* Zabráni zalomeniu textu */
    overflow: hidden;
    /* Skryje obsah presahujúci maximálnu šírku */
    text-overflow: ellipsis;
    /* Pridá ... na konci textu, ak presahuje */
    max-width: 200px;
    /* Maximálna šírka bunky (možno prispôsobiť) */
}

/* Špecifický štýl pre prvý stĺpec */
.td-title .inline-flex {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

/* Štýl pre text s odkazom v prvom stĺpci */
.document-link {
    display: flex;
    align-items: center;
    color: #2156A4;
    gap: 10px;
    text-decoration: none;
    overflow: hidden;
    /* max-width: 100%; */
    width: 100%;
    width: px;
}

.document-link img {
    flex-shrink: 0;
    /* Ensures the icon does not shrink */
}

.document-link span {
    white-space: nowrap;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    max-width: 90%;
    /* Adjust width to fit your layout */
    display: inline-block;
}

/* Ostatné stĺpce */
.results-table .td-text,
.results-table .td-createdBy,
.results-table .td-date,
.results-table .td-source {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Štýl pre akcie (ikony) */
.td-actions .inline-flex {
    display: flex;
    align-items: center;
    gap: 5px;
    /* Medzi ikonou a textom */
}

.results-table th {
    /* background-color: #f1f1f1; */
    font-weight: bold;
}

.icon {
    margin-right: 5px;
}

.inline-flex-space-between {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}

.inline-flex-spaced-evenly {
    display: inline-flex;
    width: 100%;
    justify-content: space-evenly;
}

.modalExport {
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalExport>div {
    background-color: #f4f4f4;
    padding: 10px;
    max-width: 1024px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    flex-direction: column;
}

.modalSignerViewExport {
    width: 60%;
    height: 90%;
    font-family: 'Poppins', sans-serif;
}

#closeHighlight {
    width: 3%;
    height: 30%;
    border-radius: 25%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-right: 1%;
}

.highlightHeaderContent {

    display: flex;
    align-items: center;
    border-radius: 5px;
    height: 10%;
}

.highlightSignerHeaderContent {
    background-color: lightgrey;
    display: flex;
    align-items: center;
    border-radius: 5px;
    height: 7%;
}

.highlightTitle {
    width: 75%;
    text-align: center;
    font-weight: 400;
    padding-inline: 10%;
}


.highlightSignerTitle {
    width: 75%;
    text-align: center;
    padding-inline: 10%;
    font-size: 3vh;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#closeSigner {
    width: 2%;
    height: 40%;
    border-style: solid;
    border-radius: 25%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background-color: darkgray;
    margin-right: 1%;
}

.signerIframe {
    height: 93%;
    margin: 5px;
}

.signer {
    width: 100%;
    height: 100%;
    border: none;
}

#fetchHighlight {
    margin-top: 20px;
}

#highlightLength {
    margin: 5px;
    border-top: 1px #e4e4e4 solid;
    padding-top: 20px;
    color: #2c3e50;
    white-space: pre-wrap;
    overflow-y: auto;
    overflow-x: hidden;
    height: 85%;
}

.errorMessage {
    width: 100%;
    height: 100%;
    margin-top: 30vh;
    text-align: center;
}

.pageCount {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 1rem;
}

:deep(.allMenu) {
    position: absolute !important;
}

.allMenu {
    margin: 0;
}

.menuItem {
    color: #2c3e50;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
}

.menuItem:hover {
    color: grey;
}
</style>