import { createRouter, createWebHistory } from 'vue-router'
// import Results from "@/views/Results.vue"
import ResultsNew from "@/views/ResultsNew.vue"
import Search from "@/views/Search.vue"
import DownloadSigned from "@/views/DownloadSigned.vue"

import eventBus from '../eventBus'
import store from "../store"
import axios from 'axios'
import EnvProvider from 'jvjr-docker-env'
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

const routes = [
  {
    path: "/",
    name: "Search",
    component: Search,
    meta:{requiresAuth:true},
  },
  {
    path: "/results",
    name: "Results",
    component: ResultsNew,
    meta:{requiresAuth:true},
  },
  {
    path: "/downloadsigned",
    name: "DownloadSigned",
    component: DownloadSigned,
    meta:{requiresAuth:true,
          requiresHeader:false,
          requiresFooter:false
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: Search,
    meta:{requiresAuth:true},
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to,from,next) => {
  eventBus.$emit("urlChanged",to.path)
  next()
})

async function auth(){
  console.log('Startin auth')
    try {
      if (EnvProvider.value('API_AUTH') == undefined) return false;
      const response = await axios.get(EnvProvider.value('API_AUTH') + '/auth', {withCredentials: true});
      if (response.status === 200){
        if (response.data.logged_in){
          console.log('true')
          return true
        } else {
          console.log('false')
          return false
        }
      } else {
        console.log('false')
        return false
      }
    } catch(err){
      console.log(err)
      return false
    }
}

function setLoggedUser(){
  let user = cookies.get('userName')
  if(user != null){
    cookies.set('userName', user, EnvProvider.value('COOKIE_DURATION'), null, EnvProvider.value('HOST_DOMAIN'))
    store.commit('setLoggedUser', user)
  }
  else {
    store.commit('setLoggedUser', '')
    console.log('Failed to set username from cookie')
  }
}

// Authentication logic on routes
router.beforeEach(async (to, from, next) => {
  if (to.meta && to.meta.requiresAuth === false) {
    next() // requires auth is explicitly set to false
  } else if (await auth()) {
    setLoggedUser()
    next() // i'm logged in. carry on
  } else {
    // unauthorized, redirect to login menu
    let home = new URL("/login-page", EnvProvider.value('API_AUTH'));
    window.location.href = home;
  }
})

export default router
