<template>
    <div v-if="shouldShowHeader">
      <appHeader></appHeader>
      <!-- <appMenu></appMenu> -->
    </div>
    <div class="content">
      <router-view/>
    </div>
    <appFooter v-if="shouldShowFooter"></appFooter>
</template>

<script>
import Header from "../src/components/Header.vue"
import Footer from "../src/components/Footer.vue"
// import Menu from "../src/components/Menu.vue"

export default {
  components: {
    appHeader: Header,
    appFooter: Footer,
    // appMenu: Menu,
  },
  computed: {
    shouldShowHeader() {
      const requiresHeader = this.$route.meta.requiresHeader;
      return typeof requiresHeader === 'undefined' || requiresHeader;
    },
    shouldShowFooter() {
      const requiresFooter = this.$route.meta.requiresFooter;
      return typeof requiresFooter === 'undefined' || requiresFooter;
    },
  },
  created() {
    document.title = "Minex";
    this.$store.commit('setRoot', {
      API_URL: this.$root.API_URL,
      API_SEARCH: this.$root.API_SEARCH,
      API_EXPORT: this.$root.API_EXPORT,
      API_HIGHLIGHT: this.$root.API_HIGHLIGHT
    })
  }
}
</script>

<style lang="scss" src="@/assets/css/App.scss"></style>